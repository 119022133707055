import { getRequest,deleteRequest,postRequest } from "@/http/request_service.js";
import { API } from "@/api/api.js"

export const privacyPolicy=(params)=>{
    return getRequest(API.privacyPolicy,params);
}

// 編輯聯繫我們
export const privacyPolicyEdit=(params)=>{
    return postRequest(API.privacyPolicyEdit,params);
}

